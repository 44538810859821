body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  background: linear-gradient(to bottom, #2A2A2A 500px, #000);

  overscroll-behavior: none;
  touch-action: pan-down;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background {
  width: 100%;
  background-repeat: repeat-x;
  height: 480px;
}

.ground {
  position: absolute;
  background-repeat: repeat-x;
  top: 470px;
  width: 100%;
  height: 21px;
}

.title {
  font-family: 'Alagard';
  color: #BCE3F1;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 48px;
}

p {
  font-family: 'Alagard';
  color: #446CA1;
  text-align: center;
  padding: 1rem;
}

.glow {
  transition: filter 0.3s ease;
}

.glow:hover {
  filter: drop-shadow(0 0 15px rgba(188, 227, 241, 0.6));
}